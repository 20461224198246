window.addEventListener('load', function () {
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .register('/serviceworker.js', { scope: './' })

    navigator.serviceWorker.ready.then(function (registration) {
        return registration.sync.register('sendFormData')
      }).then(function () {
        console.log('sync event registered')
      }).catch(function () {
        // system was unable to register for a sync,
        // this could be an OS-level restriction
        console.log('sync registration failed')
      })
  }
})
